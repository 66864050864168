 import React from 'react'
import { Link } from 'react-router-dom'
const Campuscare = () => {
  return (
     <>
         <div className="campuslogo">
              <Link to="https://www.pcscampuscare.org/" target="_blank"><img src="/Images/Campulogo.png" className="img-fluid float-end campus" alt="PCS Delhi"/></Link>
         </div>  
      </>
        
  )
}

export default Campuscare
 