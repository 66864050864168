import React, { useState } from 'react'
import Header from '../Component/Header'
import { Link } from 'react-router-dom'
import Footer from'../Component/Footer'
import { useEffect } from 'react'
const AlumnaeEvents = () => {
   useEffect(() => {
      // Set the page title
      document.title = 'Alumnae Events - Presentation Convent School Delhi';  
    }, []);
  const [selectoptionclass,setselectoptionclass] = useState ('2019');
   
  function handleonChange(e){
    setselectoptionclass(e.target.value) ;
}
  return (
     <>
       <Header/>
        <div className="innerslide"> 
            <ul className="breadcrumb">
                <li><Link to="/"> Home</Link> </li> 
                <li> Alumnae </li>
                <li>Alumnae Events</li>
            </ul>
       </div>
        <div className="innersec">
            <div className="container">
                <div className="row">
                    <div className="col-lg-6">
                        <h1>Alumnae Events</h1>
                    </div>
                     <div className="col-lg-6">
                     <div className="chooseyear">
                        <select id="chooseddlYear" onChange={handleonChange} value={selectoptionclass}>
                            <option value="2019">2019</option>
                            <option value="2018">2018</option> 
                       </select>
                   </div>
                     </div>
                </div>
                
            <div className="clr"></div>
                  {selectoptionclass === "2019" &&   <div className="row">
                  <div className=" col-sm-6 col-md-6 col-lg-4 col-xl-4 col-xxl-3 galleryCount sub-gallerycount"> 
                  <a data-magnify="gallery" data-src="" data-group="a" href="/Images/Alumnae-1.jpg"><img src="/Images/Alumnae-1.jpg" className=" img-fluid" alt="PCS Delhi" /></a> 
               </div>  
               <div className=" col-sm-6 col-md-6 col-lg-4 col-xl-4 col-xxl-3 galleryCount sub-gallerycount"> 
                  <a data-magnify="gallery" data-src="" data-group="a" href="/Images/Alumnae-2.jpg"><img src="/Images/Alumnae-2.jpg" className=" img-fluid" alt="PCS Delhi" /></a> 
               </div> 
               <div className=" col-sm-6 col-md-6 col-lg-4 col-xl-4 col-xxl-3 galleryCount sub-gallerycount"> 
                  <a data-magnify="gallery" data-src="" data-group="a" href="/Images/Alumnae-3.jpg"><img src="/Images/Alumnae-3.jpg" className=" img-fluid" alt="PCS Delhi" /></a> 
               </div> 
               <div className=" col-sm-6 col-md-6 col-lg-4 col-xl-4 col-xxl-3 galleryCount sub-gallerycount"> 
                  <a data-magnify="gallery" data-src="" data-group="a" href="/Images/Alumnae-4.jpg"><img src="/Images/Alumnae-4.jpg" className=" img-fluid" alt="PCS Delhi" /></a> 
               </div> 
               <div className=" col-sm-6 col-md-6 col-lg-4 col-xl-4 col-xxl-3 galleryCount sub-gallerycount"> 
                  <a data-magnify="gallery" data-src="" data-group="a" href="/Images/Alumnae-5.jpg"><img src="/Images/Alumnae-5.jpg" className=" img-fluid" alt="PCS Delhi" /></a> 
               </div> 
               <div className=" col-sm-6 col-md-6 col-lg-4 col-xl-4 col-xxl-3 galleryCount sub-gallerycount"> 
                  <a data-magnify="gallery" data-src="" data-group="a" href="/Images/Alumnae-6.jpg"><img src="/Images/Alumnae-6.jpg" className=" img-fluid" alt="PCS Delhi" /></a> 
               </div> 
               <div className=" col-sm-6 col-md-6 col-lg-4 col-xl-4 col-xxl-3 galleryCount sub-gallerycount"> 
                  <a data-magnify="gallery" data-src="" data-group="a" href="/Images/Alumnae-7.jpg"><img src="/Images/Alumnae-7.jpg" className=" img-fluid" alt="PCS Delhi" /></a> 
               </div> 
               <div className=" col-sm-6 col-md-6 col-lg-4 col-xl-4 col-xxl-3 galleryCount sub-gallerycount"> 
                  <a data-magnify="gallery" data-src="" data-group="a" href="/Images/Alumnae-8.jpg"><img src="/Images/Alumnae-8.jpg" className=" img-fluid" alt="PCS Delhi" /></a> 
               </div> 
               <div className=" col-sm-6 col-md-6 col-lg-4 col-xl-4 col-xxl-3 galleryCount sub-gallerycount"> 
                  <a data-magnify="gallery" data-src="" data-group="a" href="/Images/Alumnae-9.jpg"><img src="/Images/Alumnae-9.jpg" className=" img-fluid" alt="PCS Delhi" /></a> 
               </div> 
               <div className=" col-sm-6 col-md-6 col-lg-4 col-xl-4 col-xxl-3 galleryCount sub-gallerycount"> 
                  <a data-magnify="gallery" data-src="" data-group="a" href="/Images/Alumnae-10.jpg"><img src="/Images/Alumnae-10.jpg" className=" img-fluid" alt="PCS Delhi" /></a> 
               </div> 
               <div className=" col-sm-6 col-md-6 col-lg-4 col-xl-4 col-xxl-3 galleryCount sub-gallerycount"> 
                  <a data-magnify="gallery" data-src="" data-group="a" href="/Images/Alumnae-11.jpg"><img src="/Images/Alumnae-11.jpg" className=" img-fluid" alt="PCS Delhi" /></a> 
               </div> 
               <div className=" col-sm-6 col-md-6 col-lg-4 col-xl-4 col-xxl-3 galleryCount sub-gallerycount"> 
                  <a data-magnify="gallery" data-src="" data-group="a" href="/Images/Alumnae-12.jpg"><img src="/Images/Alumnae-12.jpg" className=" img-fluid" alt="PCS Delhi" /></a> 
               </div> 
                  </div>} 
                  {selectoptionclass === "2018" &&   <div className="row">
                  <div className=" col-sm-6 col-md-6 col-lg-4 col-xl-4 col-xxl-3 galleryCount sub-gallerycount"> 
                  <a data-magnify="gallery" data-src="" data-group="a" href="/Images/Alumnae-2018-1.jpg"><img src="/Images/Alumnae-2018-1.jpg" className=" img-fluid" alt="PCS Delhi" /></a> 
               </div>  
               <div className=" col-sm-6 col-md-6 col-lg-4 col-xl-4 col-xxl-3 galleryCount sub-gallerycount"> 
                  <a data-magnify="gallery" data-src="" data-group="a" href="/Images/Alumnae-2018-2.jpg"><img src="/Images/Alumnae-2018-2.jpg" className=" img-fluid" alt="PCS Delhi" /></a> 
               </div> 
               <div className=" col-sm-6 col-md-6 col-lg-4 col-xl-4 col-xxl-3 galleryCount sub-gallerycount"> 
                  <a data-magnify="gallery" data-src="" data-group="a" href="/Images/Alumnae-2018-3.jpg"><img src="/Images/Alumnae-2018-3.jpg" className=" img-fluid" alt="PCS Delhi" /></a> 
               </div> 
               <div className=" col-sm-6 col-md-6 col-lg-4 col-xl-4 col-xxl-3 galleryCount sub-gallerycount"> 
                  <a data-magnify="gallery" data-src="" data-group="a" href="/Images/Alumnae-2018-4.jpg"><img src="/Images/Alumnae-2018-4.jpg" className=" img-fluid" alt="PCS Delhi" /></a> 
               </div> 
               <div className=" col-sm-6 col-md-6 col-lg-4 col-xl-4 col-xxl-3 galleryCount sub-gallerycount"> 
                  <a data-magnify="gallery" data-src="" data-group="a" href="/Images/Alumnae-2018-5.jpg"><img src="/Images/Alumnae-2018-5.jpg" className=" img-fluid" alt="PCS Delhi" /></a> 
               </div> 
               <div className=" col-sm-6 col-md-6 col-lg-4 col-xl-4 col-xxl-3 galleryCount sub-gallerycount"> 
                  <a data-magnify="gallery" data-src="" data-group="a" href="/Images/Alumnae-2018-6.jpg"><img src="/Images/Alumnae-2018-6.jpg" className=" img-fluid" alt="PCS Delhi" /></a> 
               </div> 
               <div className=" col-sm-6 col-md-6 col-lg-4 col-xl-4 col-xxl-3 galleryCount sub-gallerycount"> 
                  <a data-magnify="gallery" data-src="" data-group="a" href="/Images/Alumnae-2018-7.jpg"><img src="/Images/Alumnae-2018-7.jpg" className=" img-fluid" alt="PCS Delhi" /></a> 
               </div>  
                  </div>} 
                           
            </div>  
        </div> 
       <Footer/>
     </>
  )
}

export default AlumnaeEvents
