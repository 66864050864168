import React from 'react'
import Header from '../Component/Header'
import { Link } from 'react-router-dom'
import Footer from'../Component/Footer'
import {  useEffect } from 'react';
const Sports = () => {
   useEffect(() => {
      // Set the page title
      document.title = 'The Pre-Primary Admission Details - Presentation Convent School Delhi';  
    }, []);
  return (
     <>
       <Header/>
        <div className="innerslide"> 
            <ul className="breadcrumb">
                <li><Link to="/"> Home</Link> </li> 
                <li> Activities </li>
                <li>The Pre-Primary Admission Details</li>
            </ul>
       </div>
        <div className="innersec">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <h1>The Pre-Primary Admission Details</h1>
                    </div>
                </div>
                <h5>Annual Sports Day 2023</h5>
                <p>Presentation Convent Sr. Sec. School showcased their students’ talents in a breathtaking manner in the annual sports day 2023 ‘The power of the Moment’. It elevated the spirit of the audience inspiring them to create such extra ordinary moments in their life and work. The precision with which the drills, yoga and various dances and the hurdle races performed reminded one the profound truth that life can change in an instant. The delicate and fragile structures that contains our body holds mysterious powers to make cherishing memories by embracing the present moment. There was elation and excitement, pride and pleasure, gratitude and team sprit running through the whole atmosphere. The chief guest Dr. Joseph Emmanuel, Director Academics, CBSE, congratulated the school management, staff and students and the parent community for the coordination with which they worked for the formation of character of students by providing ample opportunities, ensuring they acquire a diverse set of expertise to succeed in the 21-century workforce. Events like school sports day provides the student community with analytical, synthesizing and applying information in various contexts. They learn to take failure and success as part of life and work in a team gracefully. The budding scientists of PCS delighted the audience with a sense of hope for the future. Guests of honour included MLA Mr. Parlad Singh Sahwney, Mr. Yogesh Pal Singh, Dy Director Sports and Sr. Lizzy Mathew, Provincial Leader of Presentation Congregation, North India Province and special invitees were seen applauding the troupe with great satisfaction while distributing prizes for various games. </p>
              <p>The march past with the coordinating team of band - display mesmerized the audience. Self defense and Yoga added value and a sense of satisfaction to the viewers. The impressive grand finale left the audience spell bound. The staff and students felt proud of their ability to showcase their school in a delightful manner.  The announcement of a holiday in honour of their hard work made them extremely happy. </p>
              <p>The parents were equally enthusiastic in coming forward to participate in the special program organized for them. It was delightful to see the parents reliving their childhood memories on field with great stamina and buzzling energy. </p>
               <br/> <div className="row infra">
                     <div className="col-lg-4">
                 <a data-magnify="gallery" data-src="" data-group="a" href="/Images/sports1.jpg"><img src="/Images/sports1.jpg" className=" img-fluid" alt="PCS DELHI" /></a>  
              </div>
              <div className="col-lg-4">
                 <a data-magnify="gallery" data-src="" data-group="a" href="/Images/sports2.jpg"><img src="/Images/sports2.jpg" className=" img-fluid" alt="PCS DELHI" /></a>  
              </div>
              <div className="col-lg-4">
                 <a data-magnify="gallery" data-src="" data-group="a" href="/Images/sports3.jpg"><img src="/Images/sports3.jpg" className=" img-fluid" alt="PCS DELHI" /></a>  
              </div>
              <div className="col-lg-4">
                 <a data-magnify="gallery" data-src="" data-group="a" href="/Images/sports4.jpg"><img src="/Images/sports4.jpg" className=" img-fluid" alt="PCS DELHI" /></a>  
              </div>
              <div className="col-lg-4">
                 <a data-magnify="gallery" data-src="" data-group="a" href="/Images/sports5.jpg"><img src="/Images/sports5.jpg" className=" img-fluid" alt="PCS DELHI" /></a>  
              </div>
              <div className="col-lg-4">
                 <a data-magnify="gallery" data-src="" data-group="a" href="/Images/sports6.jpg"><img src="/Images/sports6.jpg" className=" img-fluid" alt="PCS DELHI" /></a>  
              </div>
                </div>
                <br/>
               <div className="row">
               <div className="col-lg-12">
               <img alt="PCS Delhi" src="/Images/Sports-1.png" className="img-fluid sportimg leftimg"/>
               <p><span className="green">Athletics:</span> Girls are coached in athletics from class IV upwards. The school has teams at Senior, Junior and Sub-junior levels. There is also a school team at Primary level. These teams take part primarily at races, high jump, long jump, shot put, javelin throw and discus throw. The school continues to win many medals at Zonal level. </p> 
                      
              <p><span className="green">Badminton:</span>The school has teams at Senior, Junior and Sub-junior levels. Our girls have been Zonal Champions for the last many years. </p>
              
              <p><span className="green">Basketball:</span> The school has teams at Senior, Junior and Sub-junior levels. Inter-House competitions are held to encourage students and to bring out their talent in sports. Coaching camps are organized where specially trained coaches are called to train and hone the skills of players. The school team participates in various competitions organized by CBSE and the Directorate of Education. </p>
              </div>
               <div className="col-lg-12">
              <img alt="PCS Delhi" src="/Images/Sports-2.png" className="img-fluid sportimg rightimg"/>
              <p><span className="green">Kho-Kho:</span> The school has Kho-Kho teams at Senior and Junior levels. Inter-house matches are played on regular basis. The school participates at Zonal and Inter-zone level competitions. It has been the Zonal Champions for many years. Coaching camps are organized during vacations. </p>
                      
              <p><span className="green">Softball:</span>This game was introduced in the school in 1996. Till now, the school has produced 68 National and State players. The school has won the Delhi State Championship three times. Coaching camps are organized during vacations where trained coaches are called to train and hone the skills of players. </p>  
                      
              <p><span className="green">Table tennis:</span> The school has teams at Senior and Junior levels. The school team has been participating in various competitions organized by CBSE and the Directorate of Education. </p>
                      
              <p><span className="green">Taekwondo:</span> In today's times safety and security are major concerns of parents. Taekwondo is a martial art, which helps build self-confidence in children. Girls from classes II to VIII are taught this art. </p>
                      
              <p><span className="green">Volleyball:</span> This game has recently been introduced in the school, The girls are being coached by a former National player. In the coming years, the school hopes to produce players who would do their State and their country proud.</p>     
                   
                   
                   
              </div>
                </div>
            </div>  
        </div> 
       <Footer/>
     </>
  )
}

export default Sports
