import React,{ useEffect } from 'react'
import Header from '../Component/Header'
import { Link } from 'react-router-dom'
import Footer from'../Component/Footer'
const MediaGallery = () => {
    useEffect(() => {
        // Set the page title
        document.title = 'Media Gallery - Presentation Convent School Delhi';  
      }, []);
  return (
     <>
       <Header/>
        <div className="innerslide"> 
            <ul className="breadcrumb">
                <li><Link to="/"> Home</Link> </li> 
                <li> Gallery  </li>
                <li>Media Gallery</li>
            </ul>
       </div>
        <div className="innersec">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <h1>Media Gallery</h1>
                    </div>
                </div>
                <div className="row">
                      <div className="col-lg-12">
                           <iframe src="/pdf/media.pdf" width="100%" height="800"></iframe>
                      </div> 
                 </div>
            </div>  
        </div> 
       <Footer/>
     </>
  )
}

export default MediaGallery
