import React from 'react'
import Header from '../Component/Header'
import { Link } from 'react-router-dom'
import Footer from'../Component/Footer'
import {  useEffect } from 'react';
const SeniorMiddleSchool = () => {
    useEffect(() => {
        // Set the page title
        document.title = 'Senior Middle School - Presentation Convent School Delhi';  
      }, []);
  return (
     <>
       <Header/>
        <div className="innerslide"> 
            <ul className="breadcrumb">
                <li><Link to="/"> Home</Link> </li> 
                <li>   Academic </li>
                <li>Senior Middle School</li>
            </ul>
       </div>
        <div className="innersec">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <h1>Senior Middle School</h1>
                    </div>
                </div>
                <div className="row">
                      <div className="col-lg-12">
                      <div className="clearfix">
                      <img src="/Images/Senior-School.jpg" className="img-fluid col-md-4 float-md-end mb-3 objm" alt=""/>
                      <p><strong>Curriculum: </strong>The school is affiliated to Central Board of Secondary Education (CBSE), New Delhi. It follows the guidelines and curriculum as prescribed by CBSE. The study material is taken from NCERT books. The curriculum is so designed and sub-divided that it becomes user-friendly, i.e. it becomes easier for the students to understand and comprehend the subject matter without the feeling of stress and tension.</p>
                      <p><strong>Methodology: </strong>The school is a centre for holistic education. Besides academics, the students are given lessons in value education, compassion for the poor, empowerment of the girl child and taught to be responsible citizens of our great country. The focus is on the development as a whole person, as we firmly believe in inherent potentials of every student.<strong> Here:</strong> </p> 
                      <ul>
                      <li>Students learn by DOING</li>  
                      <li>Students learn to THINK INDEPENDENTLY</li>    
                      <li>Students learn to APPLY KNOWLEDGE</li></ul>    
                      <p>Methodology is interactive, i.e. students are allowed to explore and learn. The school provides a facilitating environment for the young minds. Lessons are made interesting through group discussions, workshops, project work, slide shows, LCD display, computer aided modules, educational visits, resource persons and orientation programmes. Participation in Inter-school and Intra-school competitions is encouraged. Well-equipped laboratories provide an environment to the students to learn by doing. </p>
                      <p>Interactive teaching methodology is appropriately supported by innovative methods of teaching. Teaching-learning is made effective by encouraging students to make presentations on various topics in class whereby they are asked to gather information from various sources such as internet, newspapers, journals, life experiences, etc. Co-operative learning is another method used in class to encourage students to work in groups. This helps build team spirit, better understanding of concepts and makes learning very interesting.</p>
                      <p>Remedial teaching is an integral part towards holistic development of a student. Firstly, a student is identified on the basis of her continuous performance in class. Then, the teacher puts in her efforts to encourage and bring out her best in the student so that she is able to cope up and achieve excellence in academics.</p>
                      <p><strong>"Activity Periods"</strong> is another unique method to bring out the talents in a student. Two periods a week are set aside where special attention is given for activities science club, mathematics club, remedial teaching, debate groups, art &  craft, music, dance, dramatics, sports, etc. This provides a platform for a student where she can opt for activities of her choice. This helps her to gain confidence and self-belief in her abilities and thus perform better in other fields also.</p>
                      <p><strong>Evaluation:</strong> The school follows a system of continuous and comprehensive assessment throughout the year. The academic session is divided in four parts, which consist of two units, half-yearly examination and final examination. The purpose of Continuous and Comprehensive evaluation is intended to provide a holistic profile of a learner through assessment of both scholastic and non-scholastic aspects of education spread over the total span of instructional time in school. This also helps to identify those positive attributes of a learner, which are not usually assessed during the examinations conducted by the Examining Board. Unhealthy competition between students is strictly discouraged and emphasis is laid on a student giving her best.</p> 
                      </div>
                      </div> 
                 </div>
            </div>  
        </div> 
       <Footer/>
     </>
  )
}

export default SeniorMiddleSchool
