import React from 'react'
import Header from '../Component/Header'
import { Link } from 'react-router-dom'
import Footer from'../Component/Footer'
import {  useEffect } from 'react';
const StaffInformation = () => {
    useEffect(() => {
        // Set the page title
        document.title = 'Staff Information - Presentation Convent School Delhi';  
      }, []);
  return (
     <>
       <Header/>
        <div className="innerslide"> 
            <ul className="breadcrumb">
                <li><Link to="/"> Home</Link> </li> 
                <li> School  </li>
                <li>Staff Information</li>
            </ul>
       </div>
        <div className="innersec">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <h1>Staff Information</h1>
                    </div>
                </div>
                <div className="row">
                      <div className="col-lg-12"> 
                            <iframe src="/pdf/Staff-Statement.pdf#toolbar"  width="100%" height="600"></iframe>
                      </div> 
                 </div> 
            </div>  
        </div> 
       <Footer/>
     </>
  )
}

export default StaffInformation
