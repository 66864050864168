
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./Pages/Home";
import AdmissionDetails from "./Pages/AdmissionDetails";
import CBSEMandatory from "./Pages/CBSEMandatory";
import EnvironmentalManagement from "./Pages/EnvironmentalManagement";
import PTAMember from "./Pages/PTAMember";
import QualityManagement from "./Pages/QualityManagement";
import SchoolCabinetMembers from "./Pages/SchoolCabinetMembers";
import SchoolFees from "./Pages/SchoolFees";
import SchoolHistory from "./Pages/SchoolHistory";
import SchoolInformation from "./Pages/SchoolInformation";
import SchoolMottoObjective from "./Pages/SchoolMottoObjective";
import SchoolPrincipals from "./Pages/SchoolPrincipals";
import SMCMembers from "./Pages/SMCMembers"; 
import TeacherMessage from "./Pages/TeacherMessage"; 
import Uniform from "./Pages/Uniform"; 
import QuestionPapers from "./Pages/QuestionPapers"; 
import CbseResultAnalysis from "./Pages/CbseResultAnalysis"; 
import ChildProtection from "./Pages/ChildProtection"; 
import Infrastructure from "./Pages/Infrastructure"; 
import PrimarySchool from "./Pages/PrimarySchool"; 
import Rules from "./Pages/Rules";  
import SchoolPolicy from "./Pages/SchoolPolicy"; 
import StudySkills from "./Pages/StudySkills"; 
import SeniorMiddleSchool from "./Pages/SeniorMiddleSchool"; 
import OfficeBearers from "./Pages/OfficeBearers"; 
import Vacancy from "./Pages/Vacancy"; 
import SDG from "./Pages/SDG"; 
import Sports from "./Pages/Sports"; 
import SocialActivities from "./Pages/SocialActivities"; 
import InterSchoolCompetitions from "./Pages/InterSchoolCompetitions"; 
import BusRoute from "./Pages/BusRoute";  
import Cultural from "./Pages/Cultural";  
import Tc from "./Pages/Tc";  
import Clubs from "./Pages/Clubs"; 
import MediaGallery from "./Pages/MediaGallery";
import EHandbook from "./Pages/EHandbook";
import Magazine from "./Pages/Magazine";
import VideoGallery from "./Pages/VideoGallery";
import PhotoGallery from "./Pages/PhotoGallery";
import SubGallery from "./Pages/SubGallery";
import News from "./Pages/News";
import AlumnaeEvents from "./Pages/AlumnaeEvents";
import Events from "./Pages/Events";
import ErpSoftware from "./Pages/ErpSoftware";
import ParentGuidline from "./Pages/ParentGuidline";
import PrincipalMessage from "./Pages/PrincipalMessage";
import TopperX from "./Pages/TopperX"; 
import TopperXII from "./Pages/TopperXII";
import StaffInformation from'./Pages/StaffInformation'
import './App.css'; 
function App() {
  return (
     <>
     <BrowserRouter>
     <Routes>
        <Route path="/" element={<Home/>} />
        <Route path="/AdmissionDetails" element={<AdmissionDetails/>} />
        <Route path="/CBSEMandatory" element={<CBSEMandatory/>} />
        <Route path="/EnvironmentalManagement" element={<EnvironmentalManagement/>} />
        <Route path="/PTAMember" element={<PTAMember/>} />
        <Route path="/QualityManagement" element={<QualityManagement/>} />
        <Route path="/SchoolCabinetMembers" element={<SchoolCabinetMembers/>} />
        <Route path="/SchoolFees" element={<SchoolFees/>} />
        <Route path="/SchoolHistory" element={<SchoolHistory/>} />
        <Route path="/SchoolInformation" element={<SchoolInformation/>} />
        <Route path="/SchoolMottoObjective" element={<SchoolMottoObjective/>} />
        <Route path="/SchoolPrincipals" element={<SchoolPrincipals/>} />
        <Route path="/SMCMembers" element={<SMCMembers/>} />
        <Route path="/TeacherMessage" element={<TeacherMessage/>} /> 
        <Route path="/Uniform" element={<Uniform/>} />  
        <Route path="/QuestionPapers" element={<QuestionPapers/>} />  
        <Route path="/CbseResultAnalysis" element={<CbseResultAnalysis/>} /> 
        <Route path="/ChildProtection" element={<ChildProtection/>} /> 
        <Route path="/Infrastructure" element={<Infrastructure/>} /> 
        <Route path="/PrimarySchool" element={<PrimarySchool/>} /> 
        <Route path="/Rules" element={<Rules/>} /> 
        <Route path="/SchoolPolicy" element={<SchoolPolicy/>} />  
        <Route path="/StudySkills" element={<StudySkills/>} /> 
        <Route path="/SeniorMiddleSchool" element={<SeniorMiddleSchool/>} /> 
        <Route path="/OfficeBearers" element={<OfficeBearers/>} /> 
         <Route path="/Vacancy" element={<Vacancy/>} /> 
         <Route path="/SDG" element={<SDG/>} /> 
         <Route path="/Sports" element={<Sports/>} /> 
         <Route path="/SocialActivities" element={<SocialActivities/>} /> 
         <Route path="/InterSchoolCompetitions" element={<InterSchoolCompetitions/>} /> 
         <Route path="/BusRoute" element={<BusRoute/>} /> 
         <Route path="/Cultural" element={<Cultural/>} /> 
         <Route path="/Tc" element={<Tc/>} /> 
         <Route path="/Clubs" element={<Clubs/>} /> 
         <Route path="/MediaGallery" element={<MediaGallery/>} /> 
         <Route path="/EHandbook" element={<EHandbook/>} /> 
         <Route path="/Magazine" element={<Magazine/>} /> 
         <Route path="/VideoGallery" element={<VideoGallery/>} /> 
         <Route path="/PhotoGallery" element={<PhotoGallery/>} /> 
         <Route path="/SubGallery" element={<SubGallery/>} /> 
        <Route path="/News" element={<News/>} /> 
        <Route path="/AlumnaeEvents" element={<AlumnaeEvents/>} /> 
        <Route path="/Events" element={<Events/>} /> 
       <Route path="/ErpSoftware" element={<ErpSoftware/>} /> 
       <Route path="/ParentGuidline" element={<ParentGuidline/>} /> 
       <Route path="/PrincipalMessage" element={<PrincipalMessage/>} /> 
       <Route path="/TopperX" element={<TopperX/>} />  
       <Route path="/TopperXII" element={<TopperXII/>} />  
        <Route path="/StaffInformation" element={<StaffInformation/>} />  
     </Routes> 
    </BrowserRouter>
     </>
  );
}

export default App;
