import axios from "axios"
import { API_URL, getHeader } from "../config"

export const getHighlight = async() => {
    const { data } = await axios.get(API_URL + `/api/highlight/PCSD`);
    console.log(data)
    return data;
} 

export const getNews = async() => {
    const { data } = await axios.get(API_URL + `/api/news/PCSD`);
    console.log(data)
    return data;
} 

export const getNewsInner = async() => {
    const { data } = await axios.get(API_URL + `/api/news/All/PCSD`);
    console.log(data)
    return data;
}
export const getEvents = async() => {
    const { data } = await axios.get(API_URL + `/api/events/PCSD`);
    console.log(data)
    return data;
} 

export const getEventsinner = async() => {
    const { data } = await axios.get(API_URL + `/api/events/All/PCSD`);
    console.log(data)
    return data;
}

export const getBirthdays = async() => {
    const { data } = await axios.get(API_URL + `/api/birthdays/PCSD`);
    console.log(data)
    return data;
}

export const getAllBirthdays = async() => {
    const { data } = await axios.get(API_URL + `/api/birthdays/All/PCSD`);
    console.log(data)
    return data;
}

export const getGallery = async() => {
    const { data } = await axios.get(API_URL + `/api/gallery/PCSD`);
    return data;
} 

export const getGalleryInner = async() => {
    const { data } = await axios.get(API_URL + `/api/gallery/All/PCSD`);
    return data;
} 

export const getSubGallery = async(id) => {
    console.log(id)
    const { data } = await axios.get(API_URL + `/api/subGallery?id=${id}`);
    console.log(data)
    return data;
} 

export const getNotification = async() => {
    const { data } = await axios.get(API_URL + `/api/notification/PCSD`,getHeader());
    return data;
} 

export const getTopper = async() => {
    const { data } = await axios.get(API_URL + `/api/topper/PCSD`,getHeader());
    return data;
} 

export const getOnlineRegistration = async() => {
    const { data } = await axios.get(API_URL + `/api/onlineRegistration/PCSD`,getHeader());
    return data;
} 

export const getSliders = async() => {
    const { data } = await axios.get(API_URL + `/api/slider/PCSD`,getHeader());
    return data;
} 

export const getPrincipalMessage = async() => {
    const { data } = await axios.get(API_URL + `/api/PrincipalMessage/PCSD`, getHeader());
    return data;
} 

// export const getMedia = async() => {
//     const { data } = await axios.get(API_URL + `/api/media/SHSJ`, getHeader());
//     return data;
// } 

export const getVideo = async() => {
    const { data } = await axios.get(API_URL + `/api/video/all/PCSD`, getHeader());
    return data;
} 

export const getEHandBook = async() => {
    const { data } = await axios.get(API_URL + `/api/circular/all/PCSD`, getHeader());
    return data;
} 
export const getMagazine = async() => {
    const { data } = await axios.get(API_URL + `/api/circular/all/PCSD`, getHeader());
    return data;
} 
export const getQuestionPaper = async() => {
    const { data } = await axios.get(API_URL + `/api/video/all/PCSD`, getHeader());
    return data;
} 
export const postTransferCertificate = async(name,admission) => {
    console.log(name,admission)

    const { data } = await axios.post(API_URL + `/api/tc/PCSD`,{name,admission},getHeader());
    return data;
}
