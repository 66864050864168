import { useState, useEffect } from 'react';
import Header from '../Component/Header';
import { Link } from 'react-router-dom';
import Footer from '../Component/Footer';
import { getQuestionPaper } from '../Service/Api';

const QuestionPapers = () => {
  useEffect(() => {
    // Set the page title
    document.title = 'Question Papers - Presentation Convent School Delhi';  
  }, []);
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [selectoptionclass, setselectoptionclass] = useState('show9');

  const formatDate = (dateString) => {
    const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
    return new Date(dateString).toLocaleDateString('en-GB', options); // 'en-GB' for DD/MM/YYYY format
  };

  useEffect(() => {
    const filterData = () => {
      const filtered = data.filter(item => item.class === selectoptionclass);
      setFilteredData(filtered);
    };
    filterData();
  }, [selectoptionclass, data]);

  useEffect(() => {
    const fetchData = async () => {
      const response = await getQuestionPaper();
      setData(response);

      const currentYear = new Date().getFullYear();
      const Year = `${currentYear - 1}-${currentYear}`;

      console.log("get current Year", Year);
    };
    fetchData();
  }, []);

  const handleonChange = (e) => {
    setselectoptionclass(e.target.value);
  };

  const renderTable = () => (
    <div className="col-lg-12">
      <div className="table-responsive maintable">
        <table className="table">
          <thead>
            <tr>
              <th width="10%"><b>Sl no.</b></th>
              <th width="15%"><b>Date</b></th>
              <th width="65%"><b>Title</b></th>
              <th width="10%"><b>Download</b></th>
            </tr>
          </thead>
          <tbody>
            {filteredData.map((item, index) => (
              <tr key={item.id}>
                <td>{index + 1}</td>
                <td>{formatDate(item.date)}</td>
                <td>{item.title}</td>
                <td className="download">
                  <a href={item.link} target="_blank" rel="noopener noreferrer">View More</a>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );

  return (
    <>
      <Header />
      <div className="innerslide">
        <ul className="breadcrumb">
          <li><Link to="/"> Home</Link></li>
          <li>Academic</li>
          <li>Question Papers</li>
        </ul>
      </div>
      <div className="innersec">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <h1>Question Papers</h1>
            </div>
            <div className="col-lg-6">
              <div className="chooseyear">
                <select id="chooseddlYear" onChange={handleonChange} value={selectoptionclass}>
                  <option value="show9">Class 9</option>
                  <option value="show10">Class 10</option>
                  <option value="show11">Class 11</option>
                  <option value="show12">Class 12</option>
                </select>
              </div>
            </div>
          </div>

          <div className="clr"></div>

          {renderTable()}
        </div>
      </div>
      <Footer />
    </>
  );
};

export default QuestionPapers;
