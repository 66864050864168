import React  from 'react'
import Highlights from'../Component/Highlights' 
const Slider = () => {
 
  return (
      <>
       <div className="homeslider">
          <div id="carouselExampleControls" className="carousel slide" data-bs-ride="carousel">
           <div className="carousel-inner">
            <div className="carousel-item active">
              <img src="/Images/slider1.jpg" className="d-block w-100" alt="Pcs Delhi"/>
            </div>
            <div className="carousel-item">
              <img src="/Images/slider1.jpg" className="d-block w-100" alt="Pcs Delhi"/>
            </div>
            <div className="carousel-item">
              <img src="/Images/slider1.jpg" className="d-block w-100" alt="Pcs Delhi"/>
            </div>
          </div>  
          <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="prev">
            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
            <span className="visually-hidden">Previous</span>
          </button>
          <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="next">
            <span className="carousel-control-next-icon" aria-hidden="true"></span>
            <span className="visually-hidden">Next</span>
          </button>
        </div>
        <div className="homeslidergif">
            <img src="/Images/Mother.gif" className="img-fluid" alt="Pcs Delhi"/>
        </div>
        <Highlights/>
       </div>
      </>
  )
}

export default Slider
