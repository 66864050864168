import { useEffect } from "react";
import Header from '../Component/Header'
import { Link } from 'react-router-dom'
import Footer from'../Component/Footer'
const OfficeBearers = () => {
  useEffect(() => {
    // Set the page title
    document.title = 'Office Bearers - Presentation Convent School Delhi';  
  }, []);
  return (
     <>
       <Header/>
        <div className="innerslide"> 
            <ul className="breadcrumb">
                <li><Link to="/"> Home</Link> </li> 
                <li> Alumnae </li>
                <li>Office Bearers</li>
            </ul>
       </div>
        <div className="innersec">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <h1>Office Bearers</h1>
                    </div>
                </div>
                <div className="row">
                <div className="col-xxl-3 col-lg-4 col-md-4">
                <div className="management">
                  <img alt="PCS DELHI" src="/Images/Office-Bearers-president.jpg" className="img-fluid"/>
                  <div className="management-detils">
                    <h4>MS. I. P. THOMAS</h4>
                    <h5>PRESIDENT  <span>Batch:- 1976-77</span></h5>
                  </div>
                </div>
              </div>
                <div className="col-xxl-3 col-lg-4 col-md-4">
                <div className="management">
                  <img alt="PCS DELHI" src="/Images/Office-Bearers-vicepresident.jpg" className="img-fluid"/>
                  <div className="management-detils">
                    <h4>MS. AVNITA JOSHI </h4>
                    <h5>VICE-PRESIDENT <span>Batch:- 2012-13</span></h5>
                  </div>
                </div>
              </div>
                <div className="col-xxl-3 col-lg-4 col-md-4">
                <div className="management">
                  <img alt="PCS DELHI" src="/Images/Office-Bearers-SECRETARY.jpg" className="img-fluid"/>
                  <div className="management-detils">
                    <h4>MS. PRIYA JAIN </h4>
                    <h5>SECRETARY <span>Batch:- 2005-06</span></h5>
                  </div>
                </div>
              </div>
                <div className="col-xxl-3 col-lg-4 col-md-4">
                <div className="management">
                  <img alt="PCS DELHI" src="/Images/Office-Bearers-JOINT-SECRETARY.jpg" className="img-fluid"/>
                  <div className="management-detils">
                    <h4>MS. AYESHA ABRAR </h4>
                    <h5>JOINT SECRETARY <span>Batch:-  2007-08 </span></h5>
                  </div>
                </div>
              </div>
                <div className="col-xxl-3 col-lg-4 col-md-4">
                <div className="management">
                  <img alt="PCS DELHI" src="/Images/Office-Bearers-TREASURER.jpg" className="img-fluid"/>
                  <div className="management-detils">
                    <h4>MS. KITTY RAJPAL</h4>
                    <h5>TREASURER <span>Batch:- 1982-83 </span></h5>
                  </div>
                </div>
              </div>
          </div>
            </div>  
        </div> 
       <Footer/>
     </>
  )
}

export default OfficeBearers
